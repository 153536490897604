import { RouteRecordRaw } from 'vue-router';

import { Auth, HasAccountPermissions } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/users',
    meta: { middlewares: [Auth, HasAccountPermissions] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'UserList',
        component: () => import('./views/UserList.vue'),
        meta: { title: 'Users' },
      },
      {
        path: ':user',
        component: () => import('./layouts/UserLayout.vue'),
        children: [
          {
            path: '',
            name: 'UserGeneralView',
            component: () => import('./views/UserGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'logs',
            name: 'UserLogsView',
            component: () => import('./views/UserLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
          {
            path: 'activity',
            name: 'UserActivityView',
            component: () => import('./views/UserActivityView.vue'),
            meta: { title: ':resource: Activity' },
          },
        ],
      },
    ],
  },
  {
    path: '/user-invitation',
    name: 'UserInvitation',
    component: () => import('./views/UserInvitation.vue'),
    meta: { title: 'Invitation' },
  },
];
