import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { routes as authRoutes } from '$common/modules/auth/routes.ts';

export const routeModuleLoader = () =>
  Object.values(import.meta.glob('../modules/**/routes.ts', { eager: true })).flatMap(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (m: any) => m?.routes ?? m?.default ?? [],
  );

export const routes: RouteRecordRaw[] = [
  ...routeModuleLoader(),

  ...authRoutes,

  {
    path: '/',
    meta: { middlewares: Auth },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('$tenant/views/IndexPage.vue'),
        name: 'root',
        meta: { title: 'Dashboard' },
      },
      {
        path: 'not-found',
        component: () => import('$tenant/views/404Page.vue'),
        name: '404',
        meta: { title: 'Not Found' },
      },
    ],
  },

  // always last
  {
    path: '/:catchAll(.*)*',
    meta: { middlewares: Auth },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('$tenant/views/404Page.vue'),
      },
    ],
  },
];
