import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/festivals',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'FestivalList',
        component: () => import('./views/FestivalList.vue'),
        meta: { title: 'Festivals' },
      },
      {
        path: ':festival',
        component: () => import('./layouts/FestivalLayout.vue'),
        children: [
          {
            path: '',
            name: 'FestivalGeneralView',
            component: () => import('./views/FestivalGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          //         {
          //           path: 'logs',
          //           name: 'FestivalLogsView',
          //           component: () => import('./views/FestivalLogsView.vue'),
          //         },
        ],
      },
    ],
  },
];
