import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/profile',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('./layouts/ProfileLayout.vue'),
        children: [
          {
            path: '',
            name: 'Profile',
            component: () => import('./views/ProfileView.vue'),
            meta: { title: 'Profile' },
          },
        ],
      },
    ],
  },
];
