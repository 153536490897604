import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/contacts',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ContactList',
        component: () => import('./views/ContactList.vue'),
        meta: { title: 'Contacts' },
      },
      {
        path: ':contact',
        component: () => import('./layouts/ContactLayout.vue'),
        children: [
          {
            path: '',
            name: 'ContactGeneralView',
            component: () => import('./views/ContactGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'positions',
            name: 'ContactPositionsView',
            component: () => import('./views/ContactPositionsView.vue'),
            meta: { title: ':resource: Positions' },
          },
          {
            path: 'logs',
            name: 'ContactLogsView',
            component: () => import('./views/ContactLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
