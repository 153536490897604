import { RouteRecordRaw } from 'vue-router';

import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/deals',
    meta: { middlewares: [HasFeature('deal')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'DealList',
        component: () => import('./views/DealList.vue'),
      },
    ],
  },
];
