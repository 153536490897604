import { useFeatureFlagsStore } from './store';

import { NavigationGuardWithContext } from '$common/bootstrappers/router/middleware-processor.ts';

export const HasFeature =
  (feature: string, force = false): NavigationGuardWithContext =>
  async (
    _from,
    _to,
    next,
    // ctx: unknown
  ) => {
    const featureStore = useFeatureFlagsStore();

    if (!featureStore.exists(feature)) {
      await featureStore.askForOne(feature, force);
    }

    if (!featureStore.status(feature)) {
      next({ name: '404' });
      return false;
    }

    next();
  };

export const HasAnyFeatures =
  (features: string[], force = false): NavigationGuardWithContext =>
  async (
    _from,
    _to,
    next,
    // ctx: unknown
  ) => {
    const featureStore = useFeatureFlagsStore();

    await featureStore.askForMany(features, force);

    if (!featureStore.hasAny(features)) {
      next({ name: '404' });
      return false;
    }

    next();
  };

export const HasEveryFeatures =
  (features: string[], force = false): NavigationGuardWithContext =>
  async (
    _from,
    _to,
    next,
    // ctx: unknown
  ) => {
    const featureStore = useFeatureFlagsStore();

    await featureStore.askForMany(features, force);

    if (!featureStore.hasEvery(features)) {
      next({ name: '404' });
      return false;
    }

    next();
  };
