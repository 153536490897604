import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/shows',
    meta: { middlewares: [Auth, HasFeature('show')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'ShowList',
        component: () => import('./views/ShowList.vue'),
        meta: { title: 'Shows' },
      },
      {
        path: ':show',
        component: () => import('./layouts/ShowLayout.vue'),
        children: [
          {
            path: '',
            name: 'ShowGeneralView',
            component: () => import('./views/ShowGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'details',
            name: 'ShowDetailsView',
            component: () => import('./views/ShowDetailsView.vue'),
            meta: { title: ':resource: Details' },
          },
          {
            path: 'contacts',
            name: 'ShowContactsView',
            component: () => import('./views/ShowContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'media',
            name: 'ShowMediaView',
            component: () => import('./views/ShowMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'ShowLogsView',
            component: () => import('./views/ShowLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
