import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/companies',
    meta: { middlewares: [Auth] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'CompanyList',
        component: () => import('./views/CompanyList.vue'),
        meta: { title: 'Companies' },
      },
      {
        path: ':company',
        component: () => import('./layouts/CompanyLayout.vue'),
        children: [
          {
            path: '',
            name: 'CompanyGeneralView',
            component: () => import('./views/CompanyGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'contacts',
            name: 'CompanyContactsView',
            component: () => import('./views/CompanyContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'media',
            name: 'CompanyMediaView',
            component: () => import('./views/CompanyMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'CompanyLogsView',
            component: () => import('./views/CompanyLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
