import { RouteRecordRaw } from 'vue-router';

import { Auth } from '$common/modules/auth/middelwares.ts';
import { HasFeature } from '$common/modules/feature-flags/middlewares';

export const routes: RouteRecordRaw[] = [
  {
    path: '/tours',
    meta: { middlewares: [Auth, HasFeature('show')] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'TourList',
        component: () => import('./views/TourList.vue'),
        meta: { title: 'Tours' },
      },
      {
        path: ':tour',
        component: () => import('./layouts/TourLayout.vue'),
        children: [
          {
            path: '',
            name: 'TourGeneralView',
            component: () => import('./views/TourGeneralView.vue'),
            meta: { title: ':resource:' },
          },
          {
            path: 'contacts',
            name: 'TourContactsView',
            component: () => import('./views/TourContactsView.vue'),
            meta: { title: ':resource: Contacts' },
          },
          {
            path: 'shows',
            name: 'TourShowsView',
            component: () => import('./views/TourShowsView.vue'),
            meta: { title: ':resource: Shows' },
          },
          {
            path: 'media',
            name: 'TourMediaView',
            component: () => import('./views/TourMediaView.vue'),
            meta: { title: ':resource: Media' },
          },
          {
            path: 'logs',
            name: 'TourLogsView',
            component: () => import('./views/TourLogsView.vue'),
            meta: { title: ':resource: Logs' },
          },
        ],
      },
    ],
  },
];
