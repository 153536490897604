import { RouteRecordRaw } from 'vue-router';

import { Auth, HasAccountPermissions } from '$common/modules/auth/middelwares.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: '/rbac/roles',
    meta: { middlewares: [Auth, HasAccountPermissions] },
    component: () => import('$tenant/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'RbacRoleList',
        component: () => import('./views/RbacRoleListView.vue'),
        meta: { title: 'Roles' },
      },
      {
        path: ':role',
        component: () => import('./layouts/RbacRoleLayout.vue'),
        children: [
          {
            path: '',
            name: 'RbacRoleUserView',
            component: () => import('./views/RbacRoleUserView.vue'),
            meta: { title: ':resource: Role' },
          },
        ],
      },
    ],
  },
];
